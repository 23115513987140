import axios from 'axios';
import deepMerge from "./../_shared/deepMerge";

export default class SiluHelper {
    constructor(element, options) {
        this.element = element;
        this.options = deepMerge({}, SiluHelper.defaultOptions, options);
        this.init();
    }

    init () {
        this.addEventListener();
    }

    addEventListener() {
        const { inputs } = this.options.selectors;
        const textInputs = document.querySelectorAll(inputs.combinationText);
        textInputs.forEach(input => {
            input.addEventListener('focusout', () => {
                this.storeCombinationText(input.dataset, input.value);
            });
        })
    }

    storeCombinationText(dataset, text) {
        const { eventCategoryId, eventClassId, eventColorId } = dataset;

        axios.post('/app/event_groups/index.php?view=axios', {
            ajax_action: 'storeCombinationText',
            eventCategoryId: eventCategoryId,
            eventClassId: eventClassId,
            eventColorId: eventColorId,
            text: text
        });
    }
}

SiluHelper.defaultOptions = {
    name: "SiluHelper",
    selectors: {
        inputs: {
            combinationText: '.js-save-combination'
        }
    }
}

const dom_elements = document.querySelectorAll('.js-siluhelper');

for (let index = 0; index < dom_elements.length; index++) {
    const options = dom_elements[index].dataset.options ?
        JSON.parse(dom_elements[index].dataset.options) :
        {};
    new SiluHelper(dom_elements[index], options);
}
